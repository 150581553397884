import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = ({ open, type, message, onClose }) => {
    const storageUser = localStorage.getItem("user");
    const errorSession = 'Sua sessão expirou. Conecte-se novamente para continuar utilizando a plataforma.';

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical:'top', horizontal:'right' }}
        >
            <Alert
                severity={storageUser && type ? type : 'error'} sx={{ width: '100%' }}
                onClose={onClose}
            >
                {storageUser && message ? message : errorSession}
            </Alert>
        </Snackbar>
    );
};

export default Toast;