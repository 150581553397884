import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./auth";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const { signOut } = useContext(AuthContext);

    const api = axios.create({
        baseURL: process.env.REACT_APP_LCR_API,
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            if (error.response) {
                const status = error?.response?.status;
                const dataStatus = error?.response?.data?.message;
                if (status == 401 || dataStatus === 'Token inválido ou expirado!') {
                    signOut();
                }
            } else {
                console.error("Erro sem resposta");
            }

            return Promise.reject(error);
        }
    );

    return (
        <ApiContext.Provider value={{ api }}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    return useContext(ApiContext);
};

export default ApiProvider; 