import { IconKey, IconAnalyze } from '@tabler/icons';

const icons = {
  IconKey, IconAnalyze
};

const Rotinas = (user) => {

  const rotinas = {
    id: 'rotinas',
    title: 'Rotinas Fiscais',
    type: 'group',
    children: [
      ...(user.funcao === 'admin' ? [
        {
          id: 'ecac',
          title: 'Guias Previdenciarias (ECAC)',
          type: 'item',
          url: '/rotinas/ecac',
          icon: icons.IconKey,
          breadcrumbs: false
        },
      ] : []),
      ...(user.funcao === 'admin' ? [
        {
          id: 'das',
          title: 'Simples Nacional (DAS)',
          type: 'item',
          url: '/rotinas/das',
          icon: icons.IconKey,
          breadcrumbs: false
        },

      ] : []),
      ...(user.funcao === 'admin' ? [
        {
          id: 'mensais',
          title: 'Impostos Mensais',
          type: 'item',
          url: '/rotinas/ImpostosMensais',
          icon: icons.IconKey,
          breadcrumbs: false
        },

      ] : []),
      {
        id: 'clientPanel',
        title: 'Diagnostico Fiscal Clientes',
        type: 'item',
        url: '/rotinas/clientPanel',
        icon: icons.IconAnalyze,
        breadcrumbs: false
      }
    ]
  };

  return rotinas
}

export default Rotinas;
