import { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';


import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';


import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';


import { IconLogout, IconKey, IconSettings } from '@tabler/icons';

import { AuthContext } from '../../../../contexts/auth';

import { validateNewPassword } from 'utils';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const { signOut, user, alterPassword } = useContext(AuthContext);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleLogout = async () => {
    signOut();
    toast.success("Desconectado");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSelectedIndex(-1);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const changePassword = async () => {
    try {
      const validatePassword = validateNewPassword(newPassword, newPasswordConfirm);
      if (validatePassword.passwordOK) {
        await alterPassword(user.email, oldPassword, newPassword, newPasswordConfirm);
        handleCloseChangePassword();
      } else {
        toast.error(validatePassword?.message || 'A senha informada não atende os critérios de segurança');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Ocorreu um erro ao processar sua solicitação");
    }
  };

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={<Avatar />}
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Olá,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {user ? user.name : ''}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">{user ? user.departamento : ''}</Typography>
                    </Stack>
                  </Box>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2 }}>
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.1
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleChangePassword}
                        >
                          <ListItemIcon>
                            <IconKey stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Alterar senha</Typography>} />
                        </ListItemButton>
                      </List>
                      <Divider />
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.1
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      <Dialog
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '16px',
          maxWidth: 'calc(100% - 32px)',
        }}
      >
        <DialogTitle>Alteração de Senha</DialogTitle>
        <DialogTitle>
          A senha deve conter:<br />
          - Ao menos 8 caracteres<br />
          - Ao menos uma letra maiúscula<br />
          - Ao menos uma letra minúscula<br />
          - Ao menos um número
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              padding: '16px',
            }}
          >
            <Box sx={{ marginBottom: '16px', width: '100%' }}>
              <TextField
                id="oldPass"
                name="oldPass"
                label="Senha Antiga"
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
                fullWidth
              />
            </Box>

            <Box sx={{ marginBottom: '16px', width: '100%' }}>
              <TextField
                id="newPass"
                name="newPass"
                label="Nova Senha"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
              />
            </Box>

            <Box sx={{ marginBottom: '16px', width: '100%' }}>
              <TextField
                id="newPassConfirm"
                name="newPassConfirm"
                label="Confirme a Nova Senha"
                type="password"
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                fullWidth
              />
            </Box>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePassword}>Cancelar</Button>
          <Button onClick={changePassword}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSection;
