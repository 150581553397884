/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Toast from "../Components/CustomToast";
import { toast } from "react-toastify";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [User, setUser] = useState(null);
  const [load, setLoad] = useState(true);
  const [severityAlert, setSeverityAlert] = useState([]);
  const [messageAlert, setMessageAlert] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  const showAlert = (type, message) => {
    setSeverityAlert(type);
    setMessageAlert(message);
    setOpenAlert(true);
  }

  useEffect(() => {
    const storageUser = localStorage.getItem("user");
    if (storageUser) {
      setUser(JSON.parse(storageUser));
      setLoad(false);
    }
    setLoad(false);
  }, []);

  const urlBase = process.env.REACT_APP_LCR_API;

  const signOut = async () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const signIn = async (email, password) => {
    try {
      const payload = {
        email,
        password,
      };

      const response = await axios.post(`${urlBase}/user/auth`, payload);

      return response.data
    } catch (error) {
      console.log("error: ", error.response);
      toast.error(error.response.data.message);
      // throw error;
    }
  };

  const signInConfirm = async (user, cnpj, departamento, funcaoEmpresa) => {
    setLoad(true);
    if (cnpj) {
      user.cpf = cnpj
    } else if (Array.isArray(user.cpf)) {
      user.cpf = user.cpf[0].cnpj
    }

    if (departamento) {
      user.departamento = departamento
    } else if (Array.isArray(user.cpf)) {
      user.departamento = user.cpf[0].empresa
    }

    user.funcaoEmpresa = funcaoEmpresa ? funcaoEmpresa : "analista";

    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    showAlert('success', 'Bem vindo de volta');

    setLoad(false);

    return user;
  };

  const resetSenha = async (userData) => {
    try {
      if (!userData.email) {
        toast.error('Necessário informar um e-mail');
        return;
      }

      await axios
        .put(`${urlBase}/user/forgotPassword`, userData)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error('Não foi possível processar sua solicitação');
        });
    } catch (error) {
      toast.error('Não foi possível processar sua solicitação');
    }
  };

  const alterPassword = async (
    email,
    correntPassword,
    newPassword,
    confirmPassword
  ) => {
    try {
      // setLoading(true);
      const payload = {
        email,
        correntPassword,
        newPassword,
        confirmPassword,
      };

      await axios
        .put(`${urlBase}/user/alterPassword`, payload)
        .then((response) => {
          toast.success(response?.data?.message || "Senha alterada com sucesso!");
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!User,
        user: User,
        load,
        signOut,
        signIn,
        signInConfirm,
        setUser,
        showAlert,
        resetSenha,
        alterPassword
      }}
    >
      {children}
      <Toast
        open={openAlert}
        type={severityAlert}
        message={messageAlert}
        onClose={handleCloseAlert}
      />
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthProvider;
